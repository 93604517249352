<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <loading :active.sync="isPageLoading" is-full-page></loading>
        <div v-if="!isPageLoading">
          <div class="page-title">
            <h1>Address Books</h1>
          </div>
          <div class="page-content">
            <form>
              <div class="form-group row">
                <label for="searchKeyword" class="col-sm-2 col-form-label">Search by Keyword</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" v-model="searchKeyword" />
                </div>
                <div class="col-sm-2">
                  <button type="button" class="btn btn-primary" @click="newAddressBook">
                    <i class="fas fa-plus-circle"></i>
                    Add New
                  </button>
                </div>
              </div>
            </form>
            <ul class="list-group list-group-flush border-bottom">
              <li class="list-group-item d-flex px-2" v-for="addressBook in searchLocalDataset(getAddressBooks)" :key="addressBook.id">
                <div class="mr-auto">
                  <p class="m-0">{{ addressBook.name }}</p>
                  <p class="m-0 small-grey">{{ addressBook.contactCount.toLocaleString() }} contact(s)</p>
                </div>
                <div class="mr-auto">

                </div>
                <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column"
                   title="Edit" @click.prevent="editAddressBook(addressBook.id)">
                  <img class="mb-1" src="@/assets/document.svg" alt />
                  <span class="small-grey">Edit</span>
                </a>
                <a role="button" href="#" class="d-flex justify-content-between align-items-center flex-column ml-3"
                   title="Delete" @click.prevent="deleteAddressBook(addressBook.id)">
                  <img class="mb-1" src="@/assets/red_x.svg" alt />
                  <span class="small-grey">Delete</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--Must include both size="xxl" and class="modal-xxl" for
    larger-than-normal modal to appear-->
    <b-modal id="bv-modal-address-book" size="xxl" class="modal-xxl" hide-header hide-footer :no-close-on-backdrop="true">
      <address-book></address-book>
    </b-modal>
  </fragment>
</template>

<style lang="scss" scope>
.modal-xxl
{
  max-width:95%;
}
</style>

<script>
  import { mapGetters, mapActions } from "vuex";
  import MessageBox from "@/utils/messagebox";
  import Toast from "@/utils/toast";

  export default {
    name: "configuration-address-books",
    components: {
      'app-navbar': () => import('@/components/app-navbar.vue'),
      'address-book': () => import('@/views/configuration/components/address-book.vue')
    },
    computed: {
      ...mapGetters(["getAddressBooks"])
    },
    data: function () {
      return {
        isPageLoading: true,
        searchKeyword: null
      };
    },
    methods: {
      newAddressBook() {
        this.resetAddressBook().then(() => {
          this.$bvModal.show("bv-modal-address-book");
        });
      },
      editAddressBook(id) {
        this.fetchAddressBook(id).then(() => {
          this.$bvModal.show("bv-modal-address-book");
        });
      },
      async deleteAddressBook(id) {
        let confirm = await MessageBox.confirm(this, "Remove address book", "Are you sure you want to remove this address book?");

        if (confirm) {
          this.removeAddressBook(id).then(() => {
            Toast.success(this, "Successfully removed address book!");
          });
        }
      },
      searchLocalDataset(values) {
        if (!this.searchKeyword || this.searchKeyword === "") return values;

        return values.filter(i => i.name.includes(this.searchKeyword));
      },
      ...mapActions(["fetchAddressBook", "fetchAddressBooks", "removeAddressBook", "resetAddressBook"])
    },
    mounted: function () {
      this.fetchAddressBooks().then(() => {
        this.isPageLoading = false;
      });
    }
  };
</script>
